import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Route, Router } from '@angular/router';
import { AppNode } from '@intersystems/header';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { UsersService } from 'api';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    private usersService: UsersService,
  ) {
    const navigationEvents$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => this.updateMenu(e.urlAfterRedirects)),
      takeUntil(this._unsubscribeAll),
    );
    navigationEvents$.subscribe();

    this.getTenantInvitations();
  }

  appRoutes = this.router.config;
  menuData$ = new BehaviorSubject<AppNode[]>([]);
  activeAppId$ = new BehaviorSubject<string>('');

  invitationsCounter = 0;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  imlEnabled = false;

  updateMenu(url?) {
    if (!this.authService.user) {
      this.menuData$.next([]);
      return;
    }
    if (!url) url = this.router.url;

    if (this.invitationsCounter > 0) {
      const menuData = this.getRouteTree(this.appRoutes, url, 'nav-', this.activatedRoute.snapshot.firstChild);
      let index;

      const name = menuData.find((item, i) => {
        index = i;
        return item.id === 'nav-tenants';
      }).name;

      // if counter in menu is not true, let update it
      if (menuData[index].name.search(this.invitationsCounter.toString()) === -1) {
        menuData[index].name = name.split(' (')[0] + ' (' + this.invitationsCounter + ')';
        return this.menuData$.next(menuData);
      }
    } else this.menuData$.next(this.getRouteTree(this.appRoutes, url, 'nav-', this.activatedRoute.snapshot.firstChild));
  }

  getRouteTree(
    routes: Route[],
    url: string,
    prefix: string,
    activatedRouteSnapshot: ActivatedRouteSnapshot,
  ): AppNode[] {
    let tree: AppNode[] = [];
    const isAdmin = (this.authService.user as any).is_admin;
    const isSe = (this.authService.user as any).is_admin || (this.authService.user as any).is_se;
    routes.forEach(route => {
      if (route.data?.caption) {
        const lastPath = route.path.split('/').pop();
        let hidden = false;
        if (prefix + lastPath == 'nav-settings') hidden = true;
        //if ((prefix + lastPath)=='nav-resourcecenter') hidden=true;
        // Add an ability to hide menu items based on route guards
        if (route.canActivate)
          route.canActivate.forEach(canActivateItem => {
            const guard = this.injector.get(canActivateItem);
            if (guard && !hidden) hidden = !guard.canActivate(activatedRouteSnapshot, null);
            if (prefix + lastPath == 'nav-deployments-irislogin') hidden = true;
            if (prefix + lastPath == 'nav-deployments-iml') hidden = !this.imlEnabled || hidden;
          });
        const newNode: AppNode = {
          name: route.data.caption,
          id: prefix + lastPath,
          hidden,
        };
        if (
          url.indexOf('/' + lastPath) != -1 &&
          route.data.children &&
          url != '/deployments' &&
          url != '/deployments/create' &&
          !url.startsWith('/deployments/create/')
        ) {
          newNode.children = this.getRouteTree(
            route.data.children,
            url,
            prefix + lastPath + '-',
            activatedRouteSnapshot?.firstChild,
          );
        }
        tree.push(newNode);
      }
      if (route.path === '' && route.children) {
        tree = tree.concat(this.getRouteTree(route.children, url, prefix, activatedRouteSnapshot?.firstChild));
      }
    });
    return tree;
  }

  getTenantInvitations(): void {
    if (this.authService.user) {
      this.usersService
        .getTenantInvitations(this.authService.user.username)
        .pipe(
          map(data => {
            this.invitationsCounter = 0;
            data.invitations.forEach(item =>
              item.invitation_status === 'PENDING' ? (this.invitationsCounter += 1) : null,
            );
            return this.updateMenu();
          }),
          takeUntil(this._unsubscribeAll),
        )
        .subscribe();
    }
  }
}
